/* src/Slider.css */
.slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.slide {
    flex: none;
    width: 100%;
    scroll-snap-align: start;
}
